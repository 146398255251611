import React, { useState, useEffect } from 'react';
import './App.css'
import axios from 'axios';
import { url } from './url';
import Auth from './components/auth/auth';
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import Navbar from './components/navbar/navbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/home/home';
import SortableDataGridPage from './components/dashboard/dashboard';
import Reports from './reports/reports';
import LandingPage from './components/landingpage/landingpage';
import Subcription from './components/subscription/subcription';
import TempDashboard from './components/tempdashboard/tempdashboard';
import SignIn from './components/auth/signin';
import Footer from './components/footer/footer';
import SignUp from './components/auth/signup'

const ProtectedRoute = ({ isLogged, children }) => {
  if (!isLogged) {
    return <Navigate to="/signin" />;
  }
  return children;
};

const App = () => {

  const token = localStorage.getItem('token')
  const [isLogged, setLogged] = useState(token ? true : false)

  const [subscription, setSubscriptions] = useState(true);

  useEffect(() => {
    // console.log(token);
    axios.get(`${url}/api/subscriptions/`, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })
    .then((response) => {
      // Get the current date
      const current = new Date();

      // Parse the subscription date from the response (assuming the date is in a proper format)
      const subscribed_date = new Date(response.data[0]?.subscribe_date);
      // Calculate the difference in months
      const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // Approximation of one month
      const isNewSubscribe = (current - subscribed_date) < oneMonthInMilliseconds;

      // Set subscriptions based on the comparison
      setSubscriptions(isNewSubscribe);
    })
    .catch((error) => {
      console.error('Error fetching subscriptions:', error);
    });

  }, []);

  
  const handleLogout = () => {
    const token = localStorage.getItem('token');
    
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href="/";
    axios.post(`${url}/api/logout/`, null, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then(response => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setLogged(false)
        console.log('Logout successful', response.data);
      })
      .catch(error => {
        console.error('Logout failed', error);
      });
  };
  

  return (
    <div className='App'>
      <ToastContainer />
      {/* <BrowserRouter>
        <Navbar isLogged={isLogged} setLogged={setLogged} handleLogout={handleLogout} />
        <div className='content'>
          <Routes>
            {isLogged ? (
              <>
                <Route path="/" element={<Home subscription={subscription} />}></Route>
                <Route path="/monthly" element={<TempDashboard subscription={subscription}/>}></Route>
                <Route path="/reports" element={<Reports />}></Route>
                <Route path="/subscription" element={<Subcription subscription={subscription} />}></Route>
              </> 
            ) : (
              <>
                <Route path="/" element={<SignIn setLogged={setLogged}/>}></Route>
                <Route path="/signin" element={<SignIn setLogged={setLogged}/>}></Route>
                <Route path="/signup" element={<SignUp setLogged={setLogged}/>}></Route>                
              </>
              )
            }
          </Routes>
        </div>
        <Footer />
      </BrowserRouter> */}
      <BrowserRouter>
      <Navbar isLogged={isLogged} setLogged={setLogged} handleLogout={handleLogout} />
      <div className='content'>
        <Routes>
          <Route path="/" element={isLogged ? <Home subscription={subscription} /> : <SignIn setLogged={setLogged} />} />
          <Route path="/signin" element={<SignIn setLogged={setLogged} />} />
          <Route path="/signup" element={<SignUp setLogged={setLogged} />} />
          <Route 
            path="/monthly" 
            element={
              <ProtectedRoute isLogged={isLogged}>
                <TempDashboard subscription={subscription} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/reports" 
            element={
              <ProtectedRoute isLogged={isLogged}>
                <Reports />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/subscription" 
            element={
              <ProtectedRoute isLogged={isLogged}>
                <Subcription subscription={subscription} />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
    </div>
  );
};

export default App;
