import React, { useEffect, useState } from "react";
import { Container, Grid, CardActionArea } from "@mui/material";
import axios from "axios";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { toast } from "react-toastify";
import Loader from "../../components/loadingBar/Loading";
import { motion } from 'framer-motion';
import "./dashboard.css";

const SortableDataGridPage = () => {
  // const [data, setData] = useState([]);
  const [display_data, setDisplay_data] = useState([]);
  // const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true); // State for initial loading

  // const fetchAssets = async () => {
  //   axios.get("http://web.iqautotrade.com:5001/api/assets")
  //     .then(response => {
  //       const assetsArray = Object.keys(response.data);
  //       console.log("_______assetsArray_____:", assetsArray);
  //       setAssets(assetsArray);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching assets:", error);
  //     });
  // };
  
  const fetchDataForAsset = async (asset) => {
    const endtime = new Date().getTime() + 5 * 60 * 1000;
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        // `http://208.109.233.200:8000/api/iqoption?interval=300&count=100&endtime=${endtime}&type=signal&assets=${JSON.stringify([asset])}`,
        `https://binariasfalcon.com:8002/api/iqoption?interval=300&count=100&endtime=${endtime}&type=signal&assets=${JSON.stringify([asset])}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      
      if (response.data.code === 402) {
        // toast.error("You are not subscribed to access this page");
        return [];
      }
      // console.log("____test_response____",response.data)
      // result = response.data.data
      // const dataPerAsset = response.data.data.flat();
      // const arrangedDataPerAsset = dataPerAsset.sort((a, b) => b.to - a.to);
      // console.log("___arrangedDataPerAsset___:", arrangedDataPerAsset[0]);
      setDisplay_data(JSON.parse(response.data.data));
      return response.data.data
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      return [];
    }
  };

  // const fetchAllData = async () => {
  //   setLoading(true);
    
  //   try {
  //     const results = [];
      
  //     // Sequentially fetch data for each asset
  //     for (const asset of assets) {
  //       const dataForAsset = await fetchDataForAsset(asset);
  //       results.push(dataForAsset);
  //     }

  //     console.log("___results___", results);
      
  //     const combinedData = results.flat();
  //     const sortedData = combinedData.sort((a, b) => b.to - a.to);
  //     const now = new Date().getTime();
  //     console.log("___now__", now)
  //     const eightMinutesAgo = now - 2 * 60 * 1000;
  //     const lowLimit = now - 60 * 60 * 1000;
  //     // Sorting the combined data by the 'to' timestamp in descending order
  //     const filteredData = sortedData.filter(item => item.to * 1000 <= eightMinutesAgo && item.to * 1000 >= lowLimit );
      
  //     setData(filteredData);
  //     setLoading(false);
  //     setInitialLoading(false);
      
  //     console.log("___________filtered data__________", filteredData);
  //   } catch (error) {
  //     console.error("Error fetching data for all assets:", error);
  //     setLoading(false);
  //     setInitialLoading(false);
  //     setData([]); // or handle error state as per your app's logic
  //   }
  // };

  const convertTimestampToDateTime = (timestamp) => {
    // Convert to milliseconds and create a Date object
    const date = new Date((timestamp-300) * 1000); // Subtract 10800 seconds (3 hours)
    // Format the date to a readable string in the local time of UTC-3
    return date.toLocaleTimeString('en-US', { timeZone: 'America/Sao_Paulo' }); 
  };

  useEffect(() => {
    // fetchAssets();
    // display_data = fetchDataForAsset()
    // setDisplay_data(fetchDataForAsset())
    fetchDataForAsset()
    const intervalId = setInterval(fetchDataForAsset, 60000); // Fetch data every 1 minute
    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  // useEffect(() => {
  //   if (assets.length > 0) {
  //     setLoading(true);
  //     fetchAllData();
  //   }
  // }, [assets]);

  const [localTimeUTC3, setLocalTimeUTC3] = useState('');
  
  useEffect(() => {
    const updateLocalTimeUTC3 = () => {
      const now = new Date();
      const offsetMs = 70000; // 1 minute and 10 seconds in milliseconds
      const offsetDate = new Date(now.getTime() + offsetMs);
      const options = { timeZone: 'America/Sao_Paulo', hour: 'numeric', minute: '2-digit', hour12: true };
      const formatter = new Intl.DateTimeFormat('en-US', options);
      setLocalTimeUTC3(formatter.format(offsetDate));
    };

    const interval = setInterval(updateLocalTimeUTC3, 1000);

    return () => clearInterval(interval);
  }, []);
 
  const zoomAnimation  = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1},
  };

  
  const slideUpAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const slideRightAnimation = {
    hidden: {opacity: 0, x:30 },
    visible: {opacity: 1, x:0 },
  }

  return (
    <div>
      {/* <Loader loading={initialLoading}> */}
        <Container className="signals text-white">
        <div className="m-2 text-center">
        <motion.div
        initial="hidden"
        animate="visible"
        transition= {{duration:1, delay:0.3}}
        variants={zoomAnimation}
        >
          <Typography variant="h3" gutterBottom component="div">Latest Signals:</Typography>
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          transition= {{duration:1, delay:0.5 }}
          variants={zoomAnimation}
        >
        <Typography variant="h6" gutterBottom component="div" style={{fontWeight: "300"}}>
          All our signals are for <span style={{color:"#a2ff00"}}>M5 time</span>. Stay tuned for our upcoming signals.
        </Typography>
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          transition= {{duration:1, delay:1 }}
          variants={zoomAnimation}
        >
        <Typography className="pb-4" variant="h6" gutterBottom component="div" style={{fontWeight: "300"}}>
          {localTimeUTC3}
        </Typography>
        </motion.div>
      </div>
      <Grid container spacing={3}>
        {display_data.reverse().map((item) => {
          const winLossStyle = {
            borderRadius: "20px 0 0 20px",
            background: item.win_loss == "Loss" ? "#515151" : (item.buy_signal ? "#74b009" : "#e80101")
          };

          const winLossDisplay = item.win_loss != '';

          return (
          <Grid item xs={12} sm={6} md={3} key={item.id}>
            {/* {console.log("teste", item)} */}
            <Card sx={{ maxWidth: 345 }}>
            <CardActionArea
              sx= {{background: "#FFFFFF1A" }}
            >
                <motion.div
                  initial = "hidden"
                  animate = "visible"
                  transition={{ duration:0.5 }}
                  variants={slideUpAnimation}
                >
                <CardMedia
                  style={{objectFit: "fill"}}
                  component="img"
                  height="140"
                  image={item.buy_signal ? "/image/call.png" : "/image/put.png"}
                  alt="call or put image"
                />
                </motion.div>
                <CardContent className="text-white" style={{fontWeight:"500", display: "flex", flexDirection:"column", justifyContent:"space-between", marginLeft: "5px"}}>
                  <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                  <motion.div
                      initial = "hidden"
                      animate = "visible"
                      transition={{ duration:0.5, delay: 0.3 }}
                      variants={slideRightAnimation}
                    >
                    <Typography variant="h5" component="div">
                      M5
                    </Typography>
                    </motion.div>
                    {winLossDisplay && (<motion.div
                    initial = "hidden"
                    animate = "visible"
                    transition={{ duration:0.5, delay: 1 }}
                    variants={slideRightAnimation}
                    style={winLossStyle}
                    >
                    <Typography variant="h5" component="div" className="text-white win-loss" >
                      {item.win_loss}
                    </Typography>
                    </motion.div>
                    )}
                  </div>                    
                    <motion.div
                      initial = "hidden"
                      animate = "visible"
                      transition={{ duration:0.5, delay: 0.6 }}
                      variants={slideRightAnimation}
                    >
                    <Typography variant="h5" component="div">
                      {item.asset}
                    </Typography>
                    </motion.div>
                    <motion.div
                    initial = "hidden"
                    animate = "visible"
                    transition={{ duration:0.5, delay: 1 }}
                    variants={slideRightAnimation}
                    >
                    <Typography style={{color: "yellow"}} variant="h5" component="div">
                      {convertTimestampToDateTime(item.to + 600)}
                    </Typography>
                    </motion.div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          );
        })}
      </Grid>
        </Container>
      {/* </Loader> */}
    </div>
  );
};

export default SortableDataGridPage;
