import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import './footer.css'
import PaymentImg from './paymentmethod.png';
import SafeImg from './safe.png';
import FacebookImg from './facebook.png';
import InstagramImg from './instagram.png';
import FooterText from './footertext';
import FooterContact from './footercontact'

export default function Footer() {
  return (
    <div className="footer">
      <FooterContact />
      <FooterText />
      <MDBFooter className='social-footer text-white text-center mt-3'>
      <MDBContainer className='p-4'>
          <MDBRow>
          <MDBCol lg="4" md="6" className='mb-4 mb-md-0'>
              <h6>Payment methods</h6>
              <img src={PaymentImg} alt="PaymentImage" width={150}></img>  
          </MDBCol>
          <MDBCol lg="4" md="6" className='mb-4 mb-md-0'>
              <h6>Safety and quality</h6>
              <img src={SafeImg} alt="SafeImg" width={100}></img>  
          </MDBCol>
          <MDBCol lg="4" md="6" className='mb-4 mb-md-0'>
              <h6>Social media</h6>            
              <img src={FacebookImg} alt="FacebookImg" width={25} className="mx-1"></img>
              <img src={InstagramImg} alt="InstagramImg" width={25} className="mx-1"></img>              
          </MDBCol>

          </MDBRow>
      </MDBContainer>
      </MDBFooter>
    </div>
  );
}