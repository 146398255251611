// Navbar.js
import React, { useEffect, useState } from 'react';
import "./styles.css";
import { motion } from 'framer-motion'
import {MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse } from 'mdb-react-ui-kit';

import { Link } from 'react-router-dom';
import LOGO from './falcon.png'
import { duration } from '@mui/material';
export default function Navbar({ isLogged, handleLogout }) {

  useEffect(() => {
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  const [openNavSecond, setOpenNavSecond] = useState(false);
  const slideUpAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  const rotateZoomAnimation = {
    hidden: { opacity: 0, scale: 0, rotate: 0 },
    visible: { opacity: 1, scale: 1, rotate:360 },
  };
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'pt,es,en', 
        layout: "google.translate.TranslateElement.InlineLayout.SIMPLE",
      },
      'google_translate_element'
    );

    // const elements = document.querySelectorAll('.skiptranslate');
    //     elements.forEach((element) => {
    //         Array.from(element.childNodes).forEach((node) => {
    //             if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() === 'Powered by') {
    //                 node.parentNode.removeChild(node);
    //             }
    //         });
    //     });
        
  };
  return (
    <>
      <MDBNavbar className='navbar' expand='lg'>
        <MDBContainer>
          {/* <Link className='logo' to='/'>
          </Link> */}
          <motion.div
            initial = "hidden"
            animate = "visible"
            transition={{ duration: 1 }}
            variants={rotateZoomAnimation}
          >
            <MDBNavbarBrand href='/'>
              <img style={{"color": "white"}} src={LOGO} height={'70px'} alt="" srcset="" />
            </MDBNavbarBrand>
          </motion.div>
         
        <MDBCollapse navbar open={openNavSecond}>
          <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0 align-items-center'>
            {isLogged ? (
              <>
              <motion.div
                initial = "hidden"
                animate = "visible"
                transition={ {duration: 1, delay:0.3 }}
                variants={slideUpAnimation}
              >
                <Link className='link__' style={{textAlign:"center"}} to='/'>Home</Link>
              </motion.div>
              <motion.div
                initial = "hidden"
                animate = "visible"
                transition={ {duration: 1, delay:0.3 }}
                variants={slideUpAnimation}
              >
                <Link className='link__' style={{textAlign:"center"}} to='/monthly'>Live signals</Link>
              </motion.div>
              <motion.div
                initial = "hidden"
                animate = "visible"
                transition={ {duration: 1, delay:0.3 }}
                variants={slideUpAnimation}
              >
                <Link className='link__' style={{textAlign:"center"}} to='/reports'>Reports</Link>
              </motion.div>
              {/* <motion.div
                initial = "hidden"
                animate = "visible"
                transition={ {duration: 1, delay:0.3 }}
                variants={slideUpAnimation}
              >
                <div id="google_translate_element" className='d-flex align-items-center'> </div>
              </motion.div> */}
              <motion.div
                initial = "hidden"
                animate = "visible"
                transition={ {duration: 1, delay:0.3 }}
                variants={slideUpAnimation}
              >
                <div className='btns-' style={{display:"flex", justifyContent:"end"}}>
                  <button onClick={handleLogout} className='logout-btn'>logout</button>
                </div>
              </motion.div>
              <motion.div
                initial = "hidden"
                animate = "visible"
                transition={ {duration: 1, delay:0.3 }}
                variants={slideUpAnimation}
              >
                <Link className='link__' style={{textAlign:"center", border: "solid 2px #a2ff00"}} to='/subscription'>Subscription</Link>
              </motion.div>
              </>
            ) : <div id="google_translate_element"> </div>}
          </MDBNavbarNav>
        </MDBCollapse>
        </MDBContainer>
        <MDBNavbarToggler
          aria-expanded='false'
          aria-label='Toggle navigation'
          style={{marginLeft: '30px !important'}}
          color='white'
          size={'26px'}
          onClick={() => setOpenNavSecond(!openNavSecond)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
      </MDBNavbar>
    </>
  );
}
