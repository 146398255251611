import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
    MDBContainer,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBBtn,
    MDBInput,
    MDBCheckbox,
  } from 'mdb-react-ui-kit';
  import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { url } from '../../url';
import Cookies from 'js-cookie'; 
import axios from 'axios';
import { toast } from 'react-toastify';
import "bootstrap-icons/font/bootstrap-icons.css";

import './auth.css'
import { InputAdornment } from '@mui/material';
import LoginLogo from './new.png';
import Account from './account.png'

function SignUp({setLogged}) {
  const [justifyActive, setJustifyActive] = useState('tab1');

  const handleJustifyClick = value => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  const [username, setUsername] = useState('');
  const [isCreated, setIsCreated] = useState(false);
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const handleLogin = (e) => {

    const validateEmail = email => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    if (!validateEmail(username)) {
        toast.error("Please Enter Valid Email Address!")
        return;
    }

    if (username == '' || password == '') {
        toast.error("Fill Complete Form")
        return;
    }
      
    e.preventDefault();
    axios.post(`${url}/api/login/`, {
        email: username,
        password: password,
    }).then((res) => {
        const token = res.data.token;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        setLogged(true)
        window.location = '/'
    }).catch(()=> {
        toast.error("Incorrect Email or Password!")
    });
  };


  const handleRegister = (e) => {
    e.preventDefault();

    if (username == '' || password == '' || name == '') {
        toast.error("Fill Complete Form")
        return;
    }

    axios.post(`${url}/api/register/`, {
      first_name: name,
      password: password,
      username: username,
      email: username,
    }).then((res) => {
      const token = res.data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      toast.success("User Registered!")
      setIsCreated(true)
    });
    }

  return (
    isCreated?
    <>
      <div className='d-flex flex-column align-items-center justify-content-center px-4' style={{marginTop: "100px"}}>
        <Box
          component="img"
          sx={{
              height: 130,
              width: 130,
          }}
          alt="created an account"
          src={Account}
        />            
        <Typography className='text-white text-center d-block' style={{marginTop:"10px"}}>
            <Typography component="h1" variant="h5">
              Your account has been successfully created.
            </Typography>
            <Typography component="p" variant="p" style={{fontSize: "12px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
             Now, Please
             <Link href="/" variant="body2" className='d-flex justify-content-center text-center' style={{marginLeft:"5px"}}>
                {"refresh the page."}
            </Link>
            </Typography>
        </Typography>             
    </div>  
    </>
    :<div>
    <Container component="main" maxWidth="xs" style={{ background: "rgba(255, 255, 255, 0.1)", marginTop:"80px",}}>
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className = "mt-0"
      >
        <div className='d-flex justify-content-end login-title px-4'>            
            <Typography className='text-white text-center d-block' style={{marginRight:"20px"}}>
                <Typography component="h1" variant="h6">
                Nice to meet you!
                </Typography>
                <Typography component="p" variant="p" style={{fontSize: "12px"}}>
                Please create your Account
                </Typography>
            </Typography> 
            <Box
                component="img"
                sx={{
                    height: 15,
                    width: 30,
                    marginLeft: 4,
                    marginTop: 1,
                }}
                alt="login logo"
                src={LoginLogo}
            />
        </div>    
        <Box className='text-white' component="form" noValidate sx={{ mt: 3, mb:1, mx: 3, }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                placeholder='UserName'
                name="username"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="username"
                autoFocus
                variant='standard'
                InputLabelProps={{
                    sx: { color: "white", "&.Mui-focused": { color: "gray" } },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <i class="bi bi-person-fill" style={{ color: "white" }}></i>
                        </InputAdornment>
                    )
                }}
            /> 
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder='Email Address'
                name="email"
                autoComplete="email"
                autoFocus
                variant='standard'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputLabelProps={{
                    sx: { color: "white", "&.Mui-focused": { color: "gray" } },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <i class="bi bi-envelope-fill" style={{ color: "white" }}></i>
                        </InputAdornment>
                    )
                }}
            />
            
            <TextField
                margin='normal'
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant='standard'
                InputLabelProps={{
                    sx: { color: "white", "&.Mui-focused": { color: "gray" } },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <i class="bi bi-lock-fill" style={{ color: "white" }}></i>
                        </InputAdornment>
                    )
                }}
            />        

          <Button
            // type="submit"
            variant="contained"
            onClick={(e)=>handleRegister(e)}
            sx={{ 
                    mt: 3,
                    mb: 2,
                    backgroundColor:'rgba(255, 255, 255, 0.2)',
                    alignItems: 'center',
                    width: '50%',
                    margin: '20px auto',
                    display: 'flex',
                    padding: '10px 0',
                    fontSize: '18px',
                    borderRadius: '8px',                   
                }}
          >
           SignUp
          </Button>
        </Box>
      </Box>
      <Typography component="p" variant="p" style={{fontSize: "13px", color:"white", textAlign:"center"}}>
        Already have an account?
      </Typography>
      <Link href="/signin" variant="body2" className='d-flex justify-content-center mb-4 mx-auto text-center' style={{fontWeight:"600"}}>
            {"Sign in"}
      </Link>
    </Container>
  </div>
  );
}

export default SignUp;
