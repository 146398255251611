import React, { useState, useEffect, useRef } from 'react';
import './home.css';
import Signals from '../signals/signals';
import { Link } from 'react-router-dom';
import SortableDataGridPage from '../dashboard/dashboard'


function Home({subscription}) {

  return (
    <div className='home'>
      {/* {subscription ? '' : <div className="sub_ads">
        <Link to='/subscription'>Purchase Subscription For More Access</Link>
      </div>} */}
      
      <Signals />
       
    </div>
  );
}

export default Home;
