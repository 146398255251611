import React from 'react';
import { Link } from 'react-router-dom';
import '../landingpage/landingpage.css';  // Import the CSS file

function LandingPage() {
  return (
    <div className='page-welcome' style={{ width: '80%', marginTop: '4%', margin: '4% auto'}}>
      <h1 className='heading-text' style={{ color:"white" }}>Welcome to Our Brokerage!</h1>
      <p className='heading-text' style={{ color:"white" }}>Click on the link below to open an account:</p>
      <div className="country-flags">  {/* Apply the class for country flags container */}
        <div className="country-flag">  {/* Apply the class for each country flag */}
          <a href="https://exnova.org/lp/exnova-regframe-dark/pt/?aff=332900&aff_model=revenue">
            <img src="image/br.png" width="300px" height="auto" alt="Brazil" />
          </a>
        </div>
        <div className="country-flag">
          <a href="https://exnova.org/lp/exnova-regframe-dark/es/?aff=332900&aff_model=revenue">
            <img src="image/spain.png" width="300px" height="auto" alt="Spain" />
          </a>
        </div>
        <div className="country-flag">
          <a href="https://exnova.org/lp/exnova-regframe-dark/en/?aff=332900&aff_model=revenue">
            <img src="image/uk.png" width="300px" height="auto" alt="UK" />
          </a>
        </div>
      </div>
      <div style={{display:"flex", justifyContent:"space-evenly"}}>
        <Link to={'/signin'}>
          <button className='welcome-page-btn'>
            My Account
          </button>
        </Link>
        <div>
        {/* <a href="http://208.109.233.200:8000/admin"> */}
        <a href="https://binariasfalcon.com:8002/admin">
          <button className='welcome-page-btn'>
            Administrator
          </button>
        </a>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
