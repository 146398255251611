import { Box, Button, Container, Typography } from "@mui/material";
import React, {useState, useEffect} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { url } from "../../url";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import './subscription.css'
import subImg from './subplan.png'
import annualImg from './annual.png';
import monthlyImg from './monthly.png'

function Subcription({subscription}) {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState(null);

  const token = localStorage.getItem('token')

  useEffect(() => {

    axios.get(`${url}/api/subscriptions/`, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })
    .then((response) => {
      setSubscriptions(response.data);
      setSubscriptionId(response.data[0].id);
    })
    .catch((error) => {
      console.error('Error fetching subscriptions:', error);
    });

  }, []);

  console.log(subscriptionId);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
        mb: 3,
      }}
    >
      <Box
        component="img"
        src={subImg}
        alt="SubImg"
        sx={{
          height: 120,
          
        }}
      >
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 10,
          mt: 4,
          '@media (max-width: 800px)': {
            flexDirection: "column",
            gap: 5,
          },
        }}
      >
        <Box
          component="img"
          src={annualImg}
          alt="annualImg"
          onClick={() => {
            // window.open('https://buy.stripe.com/test_00g2bd1BMcCGc3S7ss', '_blank');
            window.open('https://buy.stripe.com/4gw00R8Ai5jRcTK5ko', '_blank');
            // setOpen(true);
            // setType('annual');
          }}
          sx={{
            height: 250,
            cursor:"pointer",
          }}
        >
        </Box>
        <Box
          component="img"
          src={monthlyImg}
          alt="monthlyImg"
          onClick={() => {
            // window.open('https://buy.stripe.com/test_8wM4jldku46ac3S5kl', '_blank');
            window.open('https://buy.stripe.com/28o00R5o6dQnf1SdQQ', '_blank');
            // setOpen(true);
            // setType('monthly');
          }}
          sx={{
            height:250,
            cursor:"pointer",
          }}
        >
        </Box>
      </Box>
      <Box
        sx={{
          mt: 5,
          color: "rgb(195 192 192)",
          maxWidth: "415px",
        }}
      >
        <Typography className="text-center sub-descritption" >
          By clicking the purchase link. you will be redirected to the final purchase page. After the purchase, <span>your account</span> will be verified and access to our platfor will be granted.
        </Typography>
        <Typography className="text-center sub-descritption">
          <span>For more informaion</span>, you can contact <span>our support</span> for prompt assistance, thank you for the preference
        </Typography>
      </Box>
      {/* <Dialog
        open={open}
        
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            // const formData = new FormData();
          
            try{
              const user = JSON.parse(localStorage.getItem("user"))
              const token = localStorage.getItem('token')

              console.log(user.id);

              const data = {
                user: user.id,
                is_subscribed: true,
              }

              if(type === 'monthly'){
                window.open('https://buy.stripe.com/test_8wM4jldku46ac3S5kl', '_blank');
              }
              else{
                window.open('https://buy.stripe.com/test_00g2bd1BMcCGc3S7ss', '_blank');
              }
              // axios.put(`${url}/api/subscriptions/${subscriptionId}/`, data, {
              //   headers: {
              //     Authorization: `Token ${token}`,
              //   },
              // }).then((res) => {
              //   toast.success(res.data);
              //   // if(type === 'monthly'){
              //   //   window.open('https://buy.stripe.com/28o00R5o6dQnf1SdQQ', '_blank');
              //   // }
              //   // else{
              //   //   window.open('https://buy.stripe.com/4gw00R8Ai5jRcTK5ko', '_blank');
              //   // }
              // }).catch((e) => {
              //   toast.error('Something went wrong. Please try again later.')
              // })
              // handleClose();
            }catch(err){
              handleClose()
              toast.error('Something went wrong. Please try again later.')
              console.log(err)
            }
          
          },
        }}
      >
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions>
      </Dialog> */}
    </Container>
  );
}

export default Subcription;
