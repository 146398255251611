import axios from 'axios';

export const getUserCountry = async () => {
  try {
    const response = await axios.get('https://ipinfo.io?token=dfa4197ac42462');
    return response.data.country;
  } catch (error) {
    console.error('Error fetching the user location', error);
    return null;
  }
};
