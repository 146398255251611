import React, { useEffect, useState } from "react";
import { Container, Grid, Button, CardActionArea, CardActions, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import "./signals.css";
import arrow from "./ico.png";
import { toast } from "react-toastify";
import { motion } from 'framer-motion';


const Signals = () => {
  const [data, setData] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState('EURUSD');
  // const [assets, setAssets] = useState([]);

  // const fetchAssets = () => {
  //   axios.get("http://web.iqautotrade.com:5001/api/assets")
  //     .then(response => {
  //       const assetsArray = Object.keys(response.data);
  //       setAssets(assetsArray);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching assets:", error);
  //     });
  // };


  const fetchData = () => {
    const endtime = new Date().getTime() + 5 * 60 * 100;
    const token = localStorage.getItem('token')

    axios
      .get(
        // `http://208.109.233.200:8000/api/iqoption?interval=300&count=100&endtime=${endtime}&type=signal_free&assets=${JSON.stringify([selectedAsset])}`,
        `https://binariasfalcon.com:8002/api/iqoption?interval=300&count=100&endtime=${endtime}&type=signal_free&assets=${JSON.stringify([selectedAsset])}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.code === 402) {
          // toast.error("You are not subscribed to access this page");
          return;
        }
        // console.log("____test_response____",response.data)
        const resultData = response.data.data.flat();
        const sortedData = resultData.sort((a, b) => b.to - a.to);

        setData(sortedData);
        console.log(sortedData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  // const handleAssetChange = (event) => {
  //   setSelectedAsset(event.target.value);
  // };

  useEffect(() => {
    fetchData();
    // fetchAssets();
  }, [selectedAsset]);

  const convertTimestampToDateTime = (timestamp) => {
    // Convert to milliseconds and create a Date object
    console.log("timestamp___:", timestamp);
    const date = new Date((timestamp - 300) * 1000); // Subtract 10800 seconds (3 hours)
    // Format the date to a readable string in the local time of UTC-3
    return date.toLocaleTimeString('en-US', { timeZone: 'America/Sao_Paulo' });
  };


    const [localTimeUTC3, setLocalTimeUTC3] = useState('');
  
    useEffect(() => {
      const updateLocalTimeUTC3 = () => {
        const now = new Date();
        const offsetMs = 70000; // 1 minute and 10 seconds in milliseconds
        const offsetDate = new Date(now.getTime() + offsetMs);
        const options = { timeZone: 'America/Sao_Paulo', hour: 'numeric', minute: '2-digit', hour12: true };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        setLocalTimeUTC3(formatter.format(offsetDate));
      };
  
      const interval = setInterval(updateLocalTimeUTC3, 1000);
  
      return () => clearInterval(interval);
    }, []);

    const zoomAnimation  = {
      hidden: { opacity: 0, scale: 0 },
      visible: { opacity: 1, scale: 1},
    };

    const slideUpAnimation = {
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0 },
    };

    const slideRightAnimation = {
      hidden: {opacity: 0, x:30 },
      visible: {opacity: 1, x:0 },
    }

  return (
    <Container className="signals text-white">
      <div className="m-2 text-center">
        <motion.div
        initial="hidden"
        animate="visible"
        transition= {{duration:1, delay:0.3}}
        variants={zoomAnimation}
        >
          <Typography variant="h3" gutterBottom component="div">Free Signals:</Typography>
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          transition= {{duration:1, delay:0.5 }}
          variants={zoomAnimation}
        >
        <Typography variant="h6" gutterBottom component="div" style={{fontWeight: "300"}}>
          All our signals are for <span style={{color:"#a2ff00"}}>M5 time</span>. Stay tuned for our upcoming signals.
        </Typography>
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          transition= {{duration:1, delay:1 }}
          variants={zoomAnimation}
        >
        <Typography className="pb-4" variant="h6" gutterBottom component="div" style={{fontWeight: "300"}}>
          {localTimeUTC3}
        </Typography>
        </motion.div>
      </div>
      <Grid container spacing={3}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item.id}>
            {console.log("signalstest", item)}
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <motion.div
                  initial = "hidden"
                  animate = "visible"
                  transition={{ duration:0.5 }}
                  variants={slideUpAnimation}
                >
                <CardMedia
                  style={{objectFit: "fill"}}
                  component="img"
                  height="140"
                  image={item.buy_signal ? "/image/call.png" : "/image/put.png"}
                  alt="call or put image"
                />
                </motion.div>
                <CardContent className="text-white" style={{fontWeight:"500"}}>
                <motion.div
                  initial = "hidden"
                  animate = "visible"
                  transition={{ duration:0.5, delay: 0.3 }}
                  variants={slideRightAnimation}
                >
                  <Typography variant="h5" component="div">
                    M5
                  </Typography>
                </motion.div>
                <motion.div
                  initial = "hidden"
                  animate = "visible"
                  transition={{ duration:0.5, delay: 0.6 }}
                  variants={slideRightAnimation}
                >
                  <Typography variant="h5" component="div">
                    {item.asset}
                  </Typography>
                </motion.div>
                <motion.div
                initial = "hidden"
                animate = "visible"
                transition={{ duration:0.5, delay: 1 }}
                variants={slideRightAnimation}
                >
                  <Typography style={{color: "yellow"}} variant="h5" component="div">
                    {convertTimestampToDateTime(item.to + 600)}
                  </Typography>
                </motion.div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Signals;
