import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import HalfCircleProgressBar from "../components/halfCircleProgressbar/HalfCircleProgressBar";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import arrow from "./ico.png";
import { toast } from "react-toastify";

import Loader from "../components/loadingBar/Loading";    //*********************************
import "../reports/reports.css";
import moreImg from './more.png'
import callImg from './call.png';
import putImg from './put.png';

Chart.register(ArcElement);

const Reports = () => {
  const [data, setData] = useState([]);
  // const [assets, setAssets] = useState([]);
  // const [loading, setLoading] = useState(true);           //***********************
  // const [winRate, setWinRate] = useState(0);
  // const [loseRate, setLoseRate] = useState(0);
  // const [orderedData, setOrderedData] = useState([]);
  // const [orderedOption, setOrderedOption] = useState("days");

  const [visibleItems, setVisibleItems] =useState(15);

  const loadMoreItems = () => {
    setVisibleItems(preVisibleItems => preVisibleItems + 15);
  }

  // const calculateWinLoseRate = (data) => {
  //   const totalSignals = data.length;
  //   const winSignals = data.filter((item) => item.win).length;
  //   const loseSignals = totalSignals - winSignals;

  //   // Initialize variables to track strategy counts
  //   let directWinCount = 0;
  //   let martingelV1Count = 0;
  //   let martingelV2Count = 0;

  //   // Iterate through data to categorize wins
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i].win) {
  //       // Determine strategy based on conditions (example logic)
  //       if (i === 0) {
  //         // First win is a direct win
  //         directWinCount++;
  //       } else if (data[i - 1].win) {
  //         // Consecutive wins could indicate Martingel strategy, differentiate v1/v2 based on more specific conditions if available
  //         martingelV1Count++;
  //       } else {
  //         // Non-consecutive wins or specific conditions for Martingel v2
  //         martingelV2Count++;
  //       }
  //     }
  //   }

  //   const winRate = totalSignals ? (winSignals / totalSignals) * 100 : 0;
  //   const loseRate = totalSignals ? (loseSignals / totalSignals) * 100 : 0;

  //   return {
  //     winRate: winRate.toFixed(2),
  //     loseRate: loseRate.toFixed(2),
  //     directWinCount,
  //     martingelV1Count,
  //     martingelV2Count,
  //   };
  // };

  // const calculateStrategy = (resResult) => {
  //   return resResult.map((item, index) => {
  //     if (index === 0) {
  //       // First trade after initial fetch, assuming it's a direct win
  //       return { ...item, strategy: "Direct Win" };
  //     } else {
  //       // Check previous trade to determine strategy
  //       const previousWin = resResult[index - 1].win;
  //       if (item.win) {
  //         if (previousWin) {
  //           // Direct win or continuation of a winning streak
  //           return { ...item, strategy: "Direct Win" };
  //         } else {
  //           // Check if Martingale v1 or v2
  //           let consecutiveLosses = 0;
  //           for (let i = index - 1; i >= 0; i--) {
  //             if (!resResult[i].win) {
  //               consecutiveLosses++;
  //             } else {
  //               break;
  //             }
  //           }
  //           if (consecutiveLosses === 1) {
  //             return { ...item, strategy: "Martingale v1" };
  //           } else if (consecutiveLosses >= 2) {
  //             return { ...item, strategy: "Martingale v2" };
  //           } else {
  //             return { ...item, strategy: "Unknown" }; // Edge case, shouldn't normally occur
  //           }
  //         }
  //       } else {
  //         // Loss (this case shouldn't occur based on your data)
  //         return { ...item, strategy: "Loss" };
  //       }
  //     }
  //   });
  // };


  // const chartData = {
  //   labels: ["Win Rate", "Lose Rate"],
  //   datasets: [
  //     {
  //       data: [winRate, loseRate],
  //       backgroundColor: ["#4CAF50", "#F44336"],
  //     },
  //   ],
  // };

  // const chartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  // };

  // const fetchAssets = () => {
  //   axios.get("http://208.109.233.200:5001/api/assets")
  //     .then(response => {
  //       const assetsArray = Object.keys(response.data);
  //       setAssets(assetsArray);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching assets:", error);
  //     });
  // };

  const fetchDataForAsset = async (asset) => {
    const endtime = new Date().getTime();
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(
        // `http://208.109.233.200:8000/api/iqoption?interval=300&count=100&endtime=${endtime}&type=win&assets=${JSON.stringify([asset])}`,
        `https://binariasfalcon.com:8002/api/iqoption?interval=300&count=100&endtime=${endtime}&type=win&assets=${JSON.stringify([asset])}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.data.code === 402) {
        // toast.error("You are not subscribed to access this page");
        return [];
      }

      // console.log("____test_response____",response.data)
      // const resResult = response.data.data.flat();
      // const resResultWithStrategy = calculateStrategy(resResult); // Calculate strategy for this asset's data

      // return resResultWithStrategy;
      setData(JSON.parse(response.data.data));
      return response.data.data
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      return [];
    }
  };

  // const fetchAllData = async () => {
  //   setLoading(true);

  //   try {
  //     const results = [];

  //     // Sequentially fetch data for each asset
  //     for (const asset of assets) {
  //       const dataForAsset = await fetchDataForAsset(asset);
  //       results.push(dataForAsset);
  //     }

  //     console.log("___results___", results);

  //     const combinedData = results.flat();

  //     // Sorting the combined data by the 'to' timestamp in descending order
  //     const sortedData = combinedData.sort((a, b) => b.to - a.to);
  //     setData(sortedData);
  //     setWinRate(calculateWinLoseRate(sortedData).winRate);
  //     setLoading(false);

  //     console.log("___________data__________", sortedData);
  //   } catch (error) {
  //     console.error("Error fetching data for all assets:", error);
  //     setLoading(false);
  //     setData([]); // or handle error state as per your app's logic
  //   }
  // };

  const convertTimestampToDateTime = (timestamp) => {
    // Convert to milliseconds and create a Date object
    const date = new Date((timestamp - 300) * 1000); // Subtract 10800 seconds (3 hours)
    // Format the date to a readable string in the local time of UTC-3
    return date.toLocaleTimeString('en-US', { timeZone: 'America/Sao_Paulo' });
  };

  useEffect(() => {
    // fetchAssets();
    fetchDataForAsset()
    const intervalId = setInterval(fetchDataForAsset, 300000);
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   if (assets.length > 0) {
  //     setLoading(true);
  //     fetchAllData();
  //   }
  // }, [assets]);

  return (
    <div>
      {/* <Loader loading={loading}> */}
        <Container className="signals">
          <HalfCircleProgressBar />

          <Grid container spacing={1}>
            {data.filter(item => item.win_loss != "").reverse().slice(0, visibleItems).map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card className={`smart-card ${item.win_loss != "Loss" ? 'win-gradient' : 'lose-gradient'}`}>
                  <CardContent>
                    <div style={{ display: "flex", flexDirection:"column", alignItems:"center" }}>
                      <Typography variant="body2" className="signal-time">
                        {convertTimestampToDateTime(item.to)}
                      </Typography>
                    
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                      <Typography variant="h4" className="signal-strategy">
                        {item.win_loss}
                      </Typography>
                        {item.sell_signal ? (
                          <img src={putImg} alt="putImg" height="20px"></img>
                        ) : (
                          <img src={callImg} alt="callImg" height="20px"></img>
                        )}
                    </div>                    
                    <Typography variant="subtitle1" className="asset-name">
                        {item.asset}
                    </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {visibleItems < data.length && (
            <div className="d-flex flex-column align-items-center mt-4">              
              <img src={moreImg} alt="moreImg" onClick={loadMoreItems} style={{width:"35px", cursor:"pointer"}} ></img>
              <p className="text-center text-white" style={{fontSize: "15px"}}> {`${visibleItems}`} REPORTS</p>
            </div>
          )}
        </Container>
      {/* </Loader> */}
    </div>
  );
};

export default Reports;
