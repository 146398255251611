import React, {useState, useEffect} from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import Exnova from './exnova.png';
import iqOption from './iqsite.png';
import './auth';
import { getUserCountry } from './geoLocation';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#1e1e1ed6',
  p: 4,
};

const LoginModal = ({ open, handleClose }) => {
    const [country, setCountry] = useState(null);

    useEffect(() => {
      const fetchCountry = async () => {
        const userCountry = await getUserCountry();
        setCountry(userCountry);
      };
      fetchCountry();
    }, []);
  
    const getLinkBroker = () => {
      switch (country) {
        case 'PT':
          return 'https://iqbroker.com/lp/new-mobile-partner/pt/?aff=332900&aff_model=revenue';
        case 'ES':
          return 'https://iqbroker.com/lp/new-mobile-partner/es/?aff=332900&aff_model=revenue';
        default:
          return 'https://iqbroker.com/lp/new-mobile-partner/en/?aff=332900&aff_model=revenue';
      }
    };

    const getLinkExnova = () => {
        switch (country) {
          case 'PT':
            return 'https://exnova.org/lp/exnova-regframe-dark/pt/?aff=332900&aff_model=revenue';
          case 'ES':
            return 'https://exnova.org/lp/exnova-regframe-dark/es/?aff=332900&aff_model=revenue';
          default:
            return 'https://exnova.org/lp/exnova-regframe-dark/en/?aff=332900&aff_model=revenue';
        }
      };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="modal-box" >
        <Button type="button" onClick={handleClose} className="btn-close btn-close-white float-right" aria-label="Close"></Button>
        <Typography id="modal-modal-title" variant="h4" component="h2" className='text-white mt-4 text-center' >
            <Typography component="h5" style={{fontSize:"20px", fontWeight:"500" }}>
            First, <span style={{color:"#18864B"}}> register</span> your account
            </Typography>
            <Typography style={{fontSize:"20px", fontWeight:"500" }}>
            On the broker to <span style={{cursor: "pointer", color:"#18864B"}} onClick={handleClose}>continue.</span>
            </Typography>
        </Typography>
        <Box id="modal-modal-description" sx={{ mt: 4, mx:6, display:"flex", flexDirection:"column" }}>            
            <a
                // component="a"
                href={getLinkExnova()}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Box
                component="img"
                alt="Exnova"
                src={Exnova}
                sx={{width:"100%"}}
                />
            </a>
            <a
                // component="a"
                href={getLinkBroker()}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Box
                    component="img"
                    alt="iq option"
                    src={iqOption}
                    sx={{width:"100%"}}
                />
            </a>            
        </Box>
        <Typography className='text-white text-center my-4 mx-auto w-50'>
            After registering your account, return to this page and register in to our system.
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoginModal;
