import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './footer.css';
import ContactImg from './support.png';

export default function FooterText() {
  return (
    <MDBFooter>
      <MDBContainer className='d-flex flex-column align-items-center'>
          <Box
            component="img"
            sx={{
                height: 35,
                width: 25,
            }}
            alt="contact img"
            src={ContactImg}
        />            
        <Typography className='text-white text-center my-2' component="p" variant="p" style={{fontSize:"12px"}}>
          For support, contact us here:
        </Typography>
      </MDBContainer>
    </MDBFooter>
  );
}