import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './footer.css';
import DownImg from './down.png';
import UpImg from './up.png';

export default function FooterText() {
  return (
    <MDBFooter className='social-footer mt-2'>
      <MDBContainer className='d-flex justify-content-center'>
      <div className='d-flex my-1 align-items-center mx-1 currency1'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="UpImg"
            src={UpImg}            
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          GBP/JPY
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency2'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="UpImg"
            src={UpImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          USD/CHF
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency3'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="DownImg"
            src={DownImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          EUR/USD
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency4'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="DownImg"
            src={DownImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          EUR/GBP
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency5'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="UpImg"
            src={UpImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          AUD/CAD
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency6'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="DownImg"
            src={DownImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          EUR/JPY
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency7'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="DownImg"
            src={DownImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          EUR/USD
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency8'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="UpImg"
            src={UpImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          USD/CHF
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency9'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="UpImg"
            src={UpImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          AUD/CAD
        </Typography>        
        </div>
        <div className='d-flex my-1 align-items-center mx-1 currency10'>
        <Box
            component="img"
            sx={{
                height: 15,
                width: 15,
            }}
            alt="DownImg"
            src={DownImg}
        />            
        <Typography className='text-white text-center d-block mx-1' component="p" variant="p">
          EUR/JPY
        </Typography>        
        </div>
      </MDBContainer>
    </MDBFooter>
  );
}