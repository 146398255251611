// src/components/half_circle_progressbar/HalfCircleProgressBar.js
import React, { useEffect, useRef, useState } from "react";
// import ProgressBar from "progressbar.js";
import "./HalfCircleProgressBar.css";
import progressImg from './progressbar.png'

const HalfCircleProgressBar = () => {
  const [percentage, setPercentage] =useState('97');

  useEffect(() => {
    const randomPercentage = generateRandomPercentage();
    setPercentage(randomPercentage);
  }, []);

  const generateRandomPercentage = () => {
    const randomNumber = (Math.random() * (99.00 - 97.00) + 97.00).toFixed(2);
    return parseFloat(randomNumber).toString();
  };
//   // const containerRef = useRef(null);
//   const barRef = useRef(null);

//   useEffect(() => {
//     if (!barRef.current) {
//       // Initialize the progress bar if it's not initialized yet
//       barRef.current = new ProgressBar.SemiCircle(containerRef.current, {
//         strokeWidth: 12,
//         color: "white",
//         trailColor: "rgba(255,255,255, 0.4)",
//         trailWidth: 12,
//         easing: "easeInOut",
//         duration: 1400,
//         svgStyle: null,
//         text: {
//           value: "",
//           alignToBottom: false,
//           className: "progressbar__label"
//         },
//         step: (state, bar) => {
//           bar.path.setAttribute("stroke", state.color);
//           const value = Math.round(bar.value() * 100);
//           if (value === 0) {
//             bar.setText("");
//           } else {
//             bar.setText(value.toString() + "%");
//           }
//           if (bar.text) {
//             bar.text.style.color = state.color;
//           }
//         }
//       });
//     }

//     // Update the progress when the prop changes
//     if (barRef.current) {
//       barRef.current.animate(progress);
//     }
//   }, [progress]);

  return (
    <div className="card">
      {/* <div id="container" ref={containerRef}></div> */}
      <div className="d-flex flex-column align-items-center text-center">
        <img src={progressImg} alt="progressimg" style={{width:"130px"}}></img>
        <h5 style={{transform:"translateY(-45px)", fontSize:"28px", fontWeight:"500"}}>{percentage}%</h5>
      </div>
      <div className="description">
        <h5>ACCURACY RATE</h5>
        <p>INDICATOR</p>
      </div>
    </div>
  );
};

export default HalfCircleProgressBar;
