import React, { useState, useEffect, useRef } from 'react';
import './tempdashboard.css';
import axios from 'axios';
import { url } from '../../url';
import Signals from '../signals/signals';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import SortableDataGridPage from '../dashboard/dashboard';
import { Alert, Space } from 'antd';


function TempDashboard({ }) {

    const [subscription, setSubscriptions] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token')
        axios.get(`${url}/api/subscriptions/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then((response) => {
                // Get the current date
                const current = new Date();

                // Parse the subscription date from the response (assuming the date is in a proper format)
                const subscribed_date = new Date(response.data[0]?.subscribe_date);
                // Calculate the difference in months
                const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // Approximation of one month
                const isNewSubscribe = (current - subscribed_date) < oneMonthInMilliseconds;

                // Set subscriptions based on the comparison
                setSubscriptions(isNewSubscribe);
                if (!isNewSubscribe) {
                    console.log(isNewSubscribe);
                    toast.error("You are not subscribed to access this page")
                }

            })
            .catch((error) => {
                console.error('Error fetching subscriptions:', error);
            });

    }, []);

    return subscription ? <SortableDataGridPage /> :
        <div className="sub_ads">
            <Link to='/subscription'>Purchase Subscription For More Access</Link>
            <Signals />
        </div>


}

export default TempDashboard;
